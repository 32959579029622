.image {
  margin: 0 auto;
}

.image img {
  object-fit: cover;
  width: clamp(270px, 40vw, 400px);
  height: clamp(270px, 40vw, 400px);
  border-radius: 50%;
  border: 10px inset var(--secondary-color);
  box-shadow: 0px 0px 24px 0px var(--secondary-color);
}

.information .list {
  display: flex;
  flex-direction: row;
  gap: 10vw;
  padding-left: 30px;
  margin-bottom: 2vh;
}

.tech-list {
  width: 30rem;
  position: relative;
  overflow: hidden;
}

.tech-list .inner {
  width: fit-content;
  display: flex;
  gap: 1rem;
  animation: loop 10s linear infinite;
}

.tech-list .inner .tech {
  display: flex;
  align-items: center;
  gap: 0 0.2rem;
  color: #e2e8f0;
  font-size: 0.9rem;
  background-color: #334155;
  padding: 0.7rem 1rem;
  border-radius: 0.4rem;
  box-shadow: 0 0.1rem 0.2rem #00000033, 0 0.1rem 0.2rem #0000004d,
    0 0.2rem 1.5rem #00000066;
}

.tech-list .inner .tech span {
  font-size: 1.2rem;
  color: #64748b;
}

.tech-list .fade {
  position: absolute;
  background: linear-gradient(
    90deg,
    #001d3d,
    transparent 30%,
    transparent 70%,
    #001d3d
  );
  inset: 0;
  pointer-events: none;
}

@keyframes loop {
  0% {
    transform: translateX(30%);
  }

  100% {
    transform: translateX(-110%);
  }
}

@media only screen and (max-width: 600px) {
  .information .list {
    justify-content: center;
  }

  .tech-list {
    width: 95%;
    margin: 0 auto;
    padding-block: 20px;
  }
}
