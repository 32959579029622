.project {
  display: flex;
  margin-bottom: 10vh;
}

.project__links ul > li,
.technologies__used ul > li {
  display: inline-block;
  padding-inline: 5px;
  text-align: center;
  vertical-align: middle;
}

.project__links i {
  color: var(--primary-color);
  height: 30px;
  width: 30px;
}

.project__links i:hover {
  fill: var(--secondary-color);
}

.project__content {
  width: 100%;
  padding: 0;
  margin: 0;
}

.project__screenshot {
  z-index: -1;
  display: none;
  margin: auto 0;
}

.project__screenshot img {
  border-radius: 10px;
  width: 35vw;
}

@media only screen and (max-width: 1200px) {
  .project {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.75);
    background-color: rgb(2, 36, 73);
  }
}

@media only screen and (min-width: 1200px) {
  .project:nth-of-type(odd) {
    flex-direction: row-reverse;
    text-align: right;
  }

  .project:nth-of-type(odd) .project__content {
    transform: translateX(-20%);
    width: 120%;
  }
  .project__screenshot {
    display: block;
  }
  .project__content {
    margin: 10px;
    background-color: rgba(0, 29, 61, 0.7);
    width: 120%;
  }
}
