@media only screen and (max-width: 600px) {
  section {
    padding: 6vw;
  }
}

@media only screen and (min-width: 600px) and (max-width: 991px) {
  section {
    padding: 7vw;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  section {
    padding: 8vw;
  }
}

@media only screen and (min-width: 1200px) {
  section {
    padding: 12vh 10vw;
  }
}
