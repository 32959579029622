*,
*::after,
*::before {
  box-sizing: border-box;
}

* {
  font: inherit;
  margin: 0;
  padding: 0;
  border: 0;
}

:root {
  /* --background-color: #001d3d;
  --primary-color: #e3d5ca;
  --secondary-color: #f28482; */

  --background-color: #001d3d;
  --primary-color: #e3d5ca;
  --secondary-color: #cea004;
}

html {
  scroll-behavior: smooth;
}

ol,
ul,
menu,
a {
  list-style: none;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
dd {
  margin: 10px;
  color: var(--primary-color);
}

dd {
  display: list-item;
  padding-inline-start: 1ch;
  font-size: clamp(12px, 5vw, 16px);
}

dd span {
  top: -9px;
  position: relative;
}

dd,
p {
  font-weight: 100;
  line-height: 22px;
  opacity: 80%;
  font-size: clamp(12px, 5vw, 16px);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Alexandria", "Alatsi", Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

/**----- Scrollbar appearance -----**/
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px black;
  background-color: white;
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--secondary-color);
}
