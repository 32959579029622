.container {
  /* display: none; */
  height: 100vh;
}

@media only screen and (min-width: 600px) {
  .container {
    width: 75%;
    margin: auto;
  }
}
