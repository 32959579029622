nav.nav {
  position: relative;
  z-index: 1;
  opacity: 0;
}

.nav__item a {
  display: block;
  color: var(--primary-color);
  padding: 1.5rem 1rem;
  margin: auto;
  animation: fadeInDown cubic-bezier(0.23, 0.94, 0.38, 0.95) 2s forwards;
}
.nav button:first-of-type {
  border: none;
  background: inherit;
  top: -70px;
}

.nav__item a::first-letter {
  text-transform: capitalize;
}

.nav .wrapper .nav__item {
  margin: auto;
}

.nav .wrapper .nav__item a:hover {
  color: var(--secondary-color);
}

.nav .nav__toggle {
  display: none;
  position: absolute;
  background-color: var(--background-color);
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
}

.nav .nav__toggle #nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

.nav .nav__toggle #nav-toggle span,
.nav .nav__toggle #nav-toggle span:before,
.nav .nav__toggle #nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: #fff;
  position: absolute;
  display: block;
  content: "";
  transition: all 300ms ease-in-out;
}

.nav .nav__toggle #nav-toggle span:before {
  top: -10px;
}

.nav .nav__toggle #nav-toggle span:after {
  bottom: -10px;
}

.nav .nav__toggle #nav-toggle.active span {
  background-color: transparent;
}

.nav .nav__toggle #nav-toggle.active span:before,
.nav .nav__toggle #nav-toggle.active span:after {
  top: 0;
}

.nav .nav__toggle #nav-toggle.active span:before {
  transform: rotate(45deg);
}

.nav .nav__toggle #nav-toggle.active span:after {
  transform: rotate(-45deg);
}

@media only screen and (max-width: 600px) {
  .nav .wrapper {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: var(--background-color);
    visibility: hidden;
    opacity: 0;
    transform: translateY(-100%);
    transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  }
  .nav .wrapper.active {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    -webkit-box-shadow: 0px 20px 16px -15px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 20px 16px -15px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 20px 16px -15px rgba(0, 0, 0, 0.75);
  }
  .nav .nav__toggle {
    display: block;
  }
}

@media only screen and (min-width: 600px) {
  .nav .wrapper {
    display: flex;
  }

  .cv-btn button {
    padding: 5px 15px;
  }
}
